import { Dropdown, Menu, Icons, Button, Space } from '@pankod/refine-antd'
import React, { useState } from 'react'
import clsx from 'clsx'
import { useCan } from '@pankod/refine-core'

import { SetupCertificateDrawer } from './SetupCertificateDrawer'
import { TemplateCertificateDrawer } from './TemplateCertificateDrawer'
import DrawerUnallocatedResource from '../DrawerUnallocatedResource'
import DrawerClassGroup from '../DrawerClassGroup'
import BulkImportParticipantDrawer, {
  useBulkImportParticipantDrawer,
} from './bulk-import-participant/BulkImportParticipantDrawer'
import ResetSertifikatModal, {
  useResetSertifikatModal,
} from '@resources/lms-management/program/program-setup/blocks/reset-sertifikat/ResetSertifikatModal'

type ConfigurationButtonProps = {
  programId?: number | string | string[]
  programLocalId?: number | string | string[]
  isPublished: boolean
  useNewCertificate?: boolean
}

const ConfigurationButton = ({
  programId,
  programLocalId,
  isPublished,
  useNewCertificate,
}: ConfigurationButtonProps) => {
  const [open, setOpen] = useState(false)
  const [setupCertificateShow, setSetupCertificateShow] = useState(false)
  const [templateCertificateShow, setTemplateCertificateShow] = useState(false)
  const [showUnallocated, setShowUnallocated] = useState(false)
  const [showClassGroup, setShowClassGroup] = useState(false)

  const {
    show: showBulkImportParticipantDrawer,
    ...bulkImportParticipantDrawerProps
  } = useBulkImportParticipantDrawer()

  const { show: showResetNewSertifikatModal, ...resetNewSertifikatModalProps } =
    useResetSertifikatModal()

  const { data: canAccessClassGroup } = useCan({
    action: 'show',
    resource: 'lms-class-group-management',
  })

  const { data: canResetCertificateProgram } = useCan({
    action: 'show',
    resource: 'reset-certificate-program',
  })

  return (
    <>
      <Dropdown
        trigger={['click']}
        onVisibleChange={setOpen}
        visible={open}
        overlay={
          <Menu
            items={[
              {
                type: 'group',
                key: 'Sertifikat',
                label: 'Sertifikat',
                children: [
                  {
                    icon: <Icons.FileDoneOutlined />,
                    label: 'Pengaturan Sertifikat',
                    key: 'Pengaturan Sertifikat',
                    onClick: () => {
                      setOpen(false)
                      setSetupCertificateShow(true)
                    },
                    disabled: useNewCertificate,
                  },
                  {
                    icon: <Icons.ProfileOutlined />,
                    label: 'Template Sertifikat',
                    key: 'Template Sertifikat',
                    onClick: () => {
                      setOpen(false)
                      setTemplateCertificateShow(true)
                    },
                  },
                  ...(useNewCertificate
                    ? [
                        {
                          icon: <Icons.FileSyncOutlined />,
                          label: 'Reset Sertifikat',
                          key: 'Reset Sertifikat',
                          onClick: () => {
                            setOpen(false)
                            showResetNewSertifikatModal(
                              programId!,
                              programLocalId!,
                            )
                          },
                          disabled: !canResetCertificateProgram?.can,
                          danger: canResetCertificateProgram?.can,
                        },
                      ]
                    : []),
                ],
              },
              ...(canAccessClassGroup?.can
                ? [
                    {
                      type: 'group' as const,
                      key: 'Webinar',
                      label: 'Webinar',
                      children: [
                        {
                          icon: <Icons.VideoCameraOutlined />,
                          label: 'Kelompok Kelas Webinar',
                          onClick: () => {
                            setOpen(false)
                            setShowClassGroup(true)
                          },
                          key: 'Kelompok Kelas Webinar',
                        },
                      ],
                    },
                  ]
                : []),
              {
                type: 'group',
                key: 'Konfigurasi Lainnya',
                label: 'Konfigurasi Lainnya',
                children: [
                  {
                    icon: <Icons.DeleteRowOutlined />,
                    label: 'Daftar Belum Teralokasi',
                    onClick: () => {
                      setOpen(false)
                      setShowUnallocated(true)
                    },
                    key: 'Daftar Belum Teralokasi',
                    disabled: isPublished,
                  },
                  {
                    icon: <Icons.UsergroupAddOutlined />,
                    label: 'Bulk Import Partisipan',
                    onClick: () => {
                      setOpen(false)
                      showBulkImportParticipantDrawer(
                        programId!,
                        programLocalId!,
                      )
                    },
                    key: 'Bulk Import Partisipan',
                    disabled: process.env.APP_ENV === 'production',
                  },
                ],
              },
            ]}
          />
        }
      >
        <Button icon={<Icons.SettingOutlined className="mr-2" />}>
          <Space>
            Konfigurasi Program Lokal
            <Icons.DownOutlined
              className={clsx(
                open && '-rotate-180',
                !open && '!delay-100',
                '!transition-transform',
              )}
            />
          </Space>
        </Button>
      </Dropdown>
      <SetupCertificateDrawer
        programId={programId}
        programLocalId={programLocalId}
        visible={setupCertificateShow}
        onClose={() => setSetupCertificateShow(false)}
      />
      <TemplateCertificateDrawer
        programId={programId}
        programLocalId={programLocalId}
        visible={templateCertificateShow}
        onClose={() => setTemplateCertificateShow(false)}
      />
      <DrawerUnallocatedResource
        visible={showUnallocated}
        onClose={() => setShowUnallocated(false)}
      />
      {canAccessClassGroup?.can && (
        <DrawerClassGroup
          visible={showClassGroup}
          onClose={() => setShowClassGroup(false)}
        />
      )}
      <BulkImportParticipantDrawer {...bulkImportParticipantDrawerProps} />
      <ResetSertifikatModal {...resetNewSertifikatModalProps} />
    </>
  )
}

export default ConfigurationButton
