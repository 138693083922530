import { DatePicker, Form, Modal } from '@pankod/refine-antd'
import dayjs, { Dayjs } from 'dayjs'
import React, { useCallback, useState } from 'react'
import locale from 'antd/lib/locale/id_ID'
import { useInvalidate, useOne, useUpdate } from '@pankod/refine-core'

import { TLMSCommonError } from 'src/interfaces/common'
import { UmpanBalikProgramResponse } from './types'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const usePeriodeModalForm = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    penilaianId: string
  }>({ show: false, penilaianId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (penilaianId: string) => {
      setShowId({ show: true, penilaianId })
    },
    visible: !!showId.show,
    penilaianId: showId.penilaianId,
    onClose,
  }
}

type PeriodeModalForm = {
  visible: boolean
  penilaianId: string
  programId: string | number
  onClose: () => void
}

const PeriodeModalForm = ({
  penilaianId,
  programId,
  visible,
  onClose,
}: PeriodeModalForm) => {
  const [form] = Form.useForm()
  const { isFetching } = useOne<
    { data: UmpanBalikProgramResponse },
    TLMSCommonError
  >({
    dataProviderName: 'lms',
    id: penilaianId,
    resource: `programs/${programId}/umpan-balik`,
    queryOptions: {
      enabled: visible,
      onSuccess: (data) => {
        form.setFieldsValue({
          penilaian_period: [
            data?.data?.data?.startDate,
            data?.data?.data?.endDate,
          ],
        })
      },
    },
  })
  const invalidate = useInvalidate()
  const { mutateAsync: doSubmit } = useUpdate()
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields()
        onClose()
      }}
      title="Ubah Periode Pengisian Umpan Balik Program"
      cancelText="Batal"
      okText="Simpan"
      destroyOnClose
      maskClosable={false}
      onOk={async () => {
        await form.validateFields()
        const { penilaian_period } = form.getFieldsValue()
        return doSubmit(
          {
            id: 'periods',
            resource: `programs/${programId}/umpan-balik/${penilaianId}`,
            values: {
              startDate: penilaian_period[0],
              endDate: penilaian_period[1],
            },
            successNotification: {
              message: 'Periode berhasil diubah.',
              type: 'success',
              description: 'Sukses',
            },
            errorNotification: (e) => {
              return showErrorNotification(
                e,
                'Terdapat gangguan saat mengubah periode',
              )
            },
          },
          {
            onSuccess: () => {
              onClose()
              invalidate({
                invalidates: ['list'],
                dataProviderName: 'lms',
                resource: `programs/${programId}/umpan-balik`,
              })
            },
          },
        )
      }}
    >
      <Form
        form={form}
        layout="vertical"
        validateMessages={{ required: '${label} harus diisi.' }}
      >
        <Form.Item
          label="Periode Penilaian"
          name="penilaian_period"
          rules={[
            { required: true },
            {
              validator(_, value) {
                if (!value || !value[0] || !value[1]) {
                  return Promise.reject(
                    new Error('Periode Penilaian harus diisi.'),
                  )
                }
                return Promise.resolve()
              },
            },
          ]}
          getValueProps={(dates) => ({
            value: dates
              ? [
                  dates[0] ? dayjs(dates[0]) : undefined,
                  dates[1] ? dayjs(dates[1]) : undefined,
                ]
              : [],
          })}
          getValueFromEvent={(date) => {
            if (!date) return [null, null]
            const [startDate, endDate]: [Dayjs, Dayjs] = date
            return [
              startDate.tz().startOf('date').toISOString(),
              endDate.tz().endOf('date').toISOString(),
            ]
          }}
        >
          <DatePicker.RangePicker
            disabled={isFetching}
            format="DD MMM YYYY"
            locale={locale.DatePicker}
            allowEmpty={[false, false]}
            disabledDate={(date) => date.isBefore(dayjs().startOf('date'))}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PeriodeModalForm
