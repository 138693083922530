import {
  Button,
  Form,
  Icons,
  Modal,
  notification,
  Select,
  useSelect,
} from '@pankod/refine-antd'
import { useCreate } from '@pankod/refine-core'
import React, { ReactNode, useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
type ExportProgramAttachmentButton = {
  children?: ReactNode
  programId?: string | number
  can?: boolean
  onDaftarUnduhanClick?: () => void
  showProgramLocalOption?: boolean
  showKelasOption?: boolean
  exportTypeOption: { label: string; value: string }[] | string
  description?: ReactNode
}
const ExportProgramAttachmentButton = ({
  programId,
  can,
  onDaftarUnduhanClick,
  exportTypeOption,
  showKelasOption,
  showProgramLocalOption,
  children,
  description,
}: ExportProgramAttachmentButton) => {
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const programLocalId = Form.useWatch('programLocalId', form)
  const formId = Form.useWatch('formId', form)
  const kelasId = Form.useWatch('kelasId', form)
  const exportType = Form.useWatch('exportType', form)

  const { selectProps: selectKelasProps, queryResult: queryKelasResult } =
    useSelect({
      dataProviderName: 'lms',
      resource: `programs/${programId}/classes`,
      queryOptions: { enabled: showModal && showKelasOption },
      optionLabel: 'name',
      optionValue: 'id',
      defaultValueQueryOptions: {
        enabled: false,
      },
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
    })
  const {
    selectProps: selectProgramLocalProps,
    queryResult: queryProgramLocalResult,
  } = useSelect({
    dataProviderName: 'lms',
    resource: `programs/${programId}/local-programs`,
    queryOptions: { enabled: showModal && showProgramLocalOption },
    optionLabel: 'name',
    optionValue: 'id',
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })

  const { mutateAsync, isLoading } = useCreate()
  return (
    <>
      {can && <span onClick={() => setShowModal(true)}>{children}</span>}
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        okButtonProps={{ loading: isLoading }}
        afterClose={() => {
          form.resetFields()
        }}
        onOk={async () => {
          await form.validateFields()

          try {
            await mutateAsync({
              dataProviderName: 'lms',
              resource: `programs/${programId}/program-attachment`,
              values: {
                type: Array.isArray(exportTypeOption)
                  ? exportType
                  : exportTypeOption,
                programLocalId:
                  typeof programLocalId === undefined ||
                  programLocalId?.length === 0
                    ? undefined
                    : programLocalId,
                kelasId:
                  typeof kelasId === undefined || kelasId?.length === 0
                    ? undefined
                    : kelasId,
                formId,
              },
              errorNotification: (error) =>
                showErrorNotification(
                  error,
                  'Terdapat gangguan saat mengunduh laporan',
                ),
              successNotification: false,
            })
            notification.success({
              key: 'download-laporan',
              duration: 6,
              description: (
                <div>
                  <p>
                    Ekspor/unduh laporan sedang diproses, harap pantau pada menu
                    "Daftar Unduhan Laporan"
                  </p>
                  <Button
                    onClick={() => {
                      notification.close('download-laporan')
                      onDaftarUnduhanClick?.()
                    }}
                    icon={<Icons.DownloadOutlined />}
                    type="primary"
                  >
                    Daftar Unduhan Laporan
                  </Button>
                </div>
              ),
              type: 'success',
              message: 'Sukses',
            })
            setShowModal(false)
          } catch (e) {
            showErrorNotification(e, 'Terdapat gangguan saat mengunduh laporan')
          }
        }}
        okText="Unduh"
        cancelText="Batal"
        title={children}
      >
        {description}
        <Form
          form={form}
          layout="vertical"
          validateMessages={{ required: '${label} harus diisi.' }}
        >
          {Array.isArray(exportTypeOption) && (
            <Form.Item
              name="exportType"
              label="Cakupan Ekspor"
              required
              rules={[{ required: true }]}
            >
              <Select placeholder="Pilih cakupan" options={exportTypeOption} />
            </Form.Item>
          )}

          {showProgramLocalOption && (
            <Form.Item name="programLocalId" label="Program Lokal">
              <Select
                {...selectProgramLocalProps}
                options={[...(selectProgramLocalProps?.options || [])]}
                loading={queryProgramLocalResult.isFetching}
                onSearch={() => {}}
                placeholder="Pilih program lokal"
                mode="multiple"
                filterOption={(input, option) =>
                  (option!.label as unknown as string)
                    .toLowerCase?.()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}

          {showKelasOption && (
            <Form.Item name="kelasId" label="Kelas">
              <Select
                {...selectKelasProps}
                loading={queryKelasResult.isFetching}
                placeholder="Pilih kelas"
                mode="multiple"
                onSearch={() => {}}
                filterOption={(input, option) =>
                  (option!.label as unknown as string)
                    .toLowerCase?.()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  )
}

export default ExportProgramAttachmentButton
