import {
  Form,
  FormProps,
  Input,
  ListProps,
  Modal,
  ModalProps,
  Table,
  useTable,
} from '@pankod/refine-antd'
import React, { useMemo } from 'react'
import { CrudFilters, LogicalFilter } from '@pankod/refine-core'

import Filter from '@components/ContentFilter'
import { TLMSAdmin } from '@resources/lms-management/admin/types'
import { TCommonError } from 'src/interfaces/common'

type AddAdminModalProps = {
  name?: string
  modalProps: ModalProps
  formProps: FormProps
  adminListProps: ListProps<TLMSAdmin>
}

const AddAdminModal = ({
  modalProps,
  formProps,
  adminListProps,
  name,
}: AddAdminModalProps) => {
  const {
    tableQueryResult: queryAdminResult,
    tableProps,
    filters,
    searchFormProps,
  } = useTable<
    {
      email: string
      disabled: boolean
    },
    TCommonError
  >({
    resource: 'admins',
    dataProviderName: 'lms',
    queryOptions: {
      enabled: modalProps.visible,
    },
    permanentFilter: [
      {
        field: 'role',
        operator: 'eq',
        value: 'admin-entitas',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      // @ts-expect-error
      const { email } = params
      filters.push(
        {
          field: 'email',
          operator: 'eq',
          value: email,
        },
        {
          field: 't',
          operator: 'eq',
          value: new Date().getTime(),
        },
      )
      return filters
    },
  })

  const addAdminList = useMemo(() => {
    const currentAdmins =
      adminListProps.dataSource?.map(({ email }) => email) || []
    return (
      queryAdminResult?.data?.data?.reduce(
        (admins, admin) => {
          return [
            ...admins,
            {
              email: admin.email,
              disabled: currentAdmins.includes(admin.email),
            },
          ]
        },
        [] as { email: string; disabled: boolean }[],
      ) || []
    )
  }, [adminListProps.dataSource, queryAdminResult?.data?.data])

  const selectedRowKeys = Form.useWatch('emails', formProps?.form)

  return (
    <Modal
      {...modalProps}
      destroyOnClose
      title={`Tambah Admin pada Entitas "${name}"`}
      okText="Tambah"
      cancelText="Kembali"
      maskClosable={false}
      centered
    >
      <Filter
        layout="inline"
        formProps={searchFormProps}
        filters={filters as LogicalFilter[]}
        items={[
          {
            label: 'Cari berdasarkan email',
            name: 'email',
            component: (
              <Input
                placeholder="john.doe@belajar.id"
                autoComplete="off"
                allowClear
                className="!w-[500px]"
              />
            ),
          },
        ]}
      />
      <Form {...formProps} layout="vertical">
        <Form.Item hidden name="emails">
          <Input />
        </Form.Item>
        <Table
          {...tableProps}
          dataSource={addAdminList}
          scroll={{ y: 350 }}
          rowKey="email"
          columns={[
            {
              title: 'Email',
              dataIndex: 'email',
            },
          ]}
          rowSelection={{
            selectedRowKeys: selectedRowKeys || [],
            onChange(selectedRowKeys) {
              formProps?.form?.setFieldsValue({ emails: selectedRowKeys || [] })
            },
            preserveSelectedRowKeys: true,
            getCheckboxProps: (record: { disabled: boolean }) => ({
              disabled: record.disabled,
            }),
          }}
        />
      </Form>
    </Modal>
  )
}

export default AddAdminModal
