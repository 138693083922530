import {
  Button,
  Form,
  Modal,
  Popconfirm,
  Radio,
  Space,
} from '@pankod/refine-antd'
import { useCreate } from '@pankod/refine-core'
import React, { useCallback, useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'

export const useResetSertifikatModal = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number | string[]
    programLocalId?: number | string | string[]
  }>({ show: false, programId: '', programLocalId: undefined })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])
  return {
    show: (
      programId: number | string | string[],
      programLocalId?: number | string | string[],
    ) => {
      setShowId({ show: true, programId, programLocalId })
    },
    visible: !!showId.show,
    programId: showId.programId,
    programLocalId: showId.programLocalId,
    onClose,
  }
}

type ResetSertifikatModalProps = {
  visible: boolean
  programId: number | string | string[]
  programLocalId?: number | string | string[]
  onClose: () => void
}

const ResetSertifikatModal = ({
  programId,
  programLocalId,
  onClose,
  visible,
}: ResetSertifikatModalProps) => {
  const { mutateAsync } = useCreate()
  const [form] = Form.useForm()
  const kapabilitas = Form.useWatch('kapabilitas', form)
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onClose()
        form.resetFields()
      }}
      footer={
        <Space>
          <Button
            onClick={() => {
              onClose()
              form.resetFields()
            }}
          >
            Kembali
          </Button>
          <Popconfirm
            disabled={!kapabilitas}
            onConfirm={async () => {
              const resource = !programLocalId
                ? `v1/programs/${programId}/reset-certificate`
                : `v1/programs/${programId}/program-locals/${programLocalId}/reset-certificate`
              return mutateAsync(
                {
                  resource,
                  dataProviderName: 'lms',
                  values: {
                    kapabilitas,
                  },
                  successNotification: {
                    message: 'Sertifikat berhasil direset',
                    type: 'success',
                    description: 'Sukses',
                  },
                  errorNotification: (e) =>
                    showErrorNotification(
                      e,
                      'Terdapat gangguan saat reset sertifikat',
                    ),
                },
                {
                  onSuccess: () => {
                    onClose()
                    form.resetFields()
                  },
                },
              )
            }}
            placement="bottom"
            title={
              <>
                Apakah Anda yakin ingin mereset{' '}
                <strong>
                  seluruh sertifikat pada{' '}
                  {programLocalId
                    ? `Program Lokal ID: ${programLocalId}`
                    : `Program ID: ${programId}`}
                </strong>{' '}
                ini?
                <br />
                Jika iya, semua user dengan kapabilitas terpilih yang sudah
                mencetak sertifikat harus melakukan cetak ulang.
              </>
            }
            okButtonProps={{ danger: true }}
            cancelText="Batal"
            okText="Lanjutkan"
          >
            <Button danger type="primary" disabled={!kapabilitas}>
              Reset Sertifikat
            </Button>
          </Popconfirm>
        </Space>
      }
      destroyOnClose
      maskClosable={false}
      title={`Reset Sertifikat Level ${programLocalId ? 'Program Lokal' : 'Program'}`}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={async ({ kapabilitas }: { kapabilitas: string }) => {
          const resource = !programLocalId
            ? `v1/programs/${programId}/reset-certificate`
            : `v1/programs/${programId}/program-locals/${programLocalId}/reset-certificate`
          return mutateAsync({
            resource,
            dataProviderName: 'lms',
            values: {
              kapabilitas,
            },
            successNotification: {
              message: 'Sertifikat berhasil direset',
              type: 'success',
              description: 'Sukses',
            },
            errorNotification: (e) =>
              showErrorNotification(
                e,
                'Terdapat gangguan saat reset sertifikat',
              ),
          })
        }}
      >
        <Form.Item
          name="kapabilitas"
          label="Pilih Role/Kapabilitas"
          required
          rules={[{ required: true, message: 'Role/kapabilitas harus diisi' }]}
        >
          <Radio.Group>
            <Space direction="vertical">
              {[
                {
                  label: 'Peserta',
                  value: 'peserta',
                },
                {
                  label: 'Fasilitator',
                  value: 'instruktur',
                },
                {
                  label: 'Pengajar Praktik',
                  value: 'asisten',
                },
                ...(!programLocalId
                  ? [
                      {
                        label: 'Instruktur Webinar',
                        value: 'instruktur_webinar',
                      },
                    ]
                  : []),
              ].map(({ label, value }) => (
                <Radio value={value} key={value}>
                  {label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ResetSertifikatModal
