import {
  Button,
  Divider,
  EditButton,
  Icons,
  Modal,
  Result,
  Show,
  Space,
  Tabs,
  useModalForm,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  useCan,
  useDelete,
  useInvalidate,
  useNavigation,
  useOne,
  useResource,
} from '@pankod/refine-core'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'

import { TCommonResponse, TCommonError } from 'src/interfaces/common'
import { PopDeleteConfirm } from '../components/PopDeleteConfirm'
import { ModalForm } from './blocks/ModalForm'
import { NestedBreadcrumb } from '../components/NestedBreadcrumb'
import TabKelasDetail from './blocks/TabKelasDetail'
import { TLMSClass, TLMSClassRequest } from './types'
import {
  TableClassParticipant,
  TableParticipantAllocation,
} from '@components/DataTableParticipants'
import { PROGRAM_LOCAL_STATE, TLMSProgramLocal } from '../program-lokal/types'
import { TabKelompokMurid } from './blocks/TabKelompokMurid'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { PROGRAM_STATE, TLMSProgramDetail } from '../program/types'
import { CreateParticipantSection } from '@components/DataTableParticipants/blocks/CreateParticipantSection'
import { useProgramStore } from '../program/store'

export const LMSClassManagementShow: React.FC<IResourceComponentsProps> = ({
  options,
}) => {
  const { query } = useRouter()
  const { listUrl, goBack, showUrl } = useNavigation()
  const { id } = useResource({
    resourceNameOrRouteName: 'lms-class-management',
  })
  const programId = query.programId as string
  const programLocalId = query.programLocalId as string

  const CLASS_RESOURCE = `programs/${programId}/local-programs/${programLocalId}/classes`
  const PROGRAM_LOCAL_RESOURCE = `programs/${programId}/local-programs`
  const PROGRAM_RESOURCE = `programs`

  const [activeKey, setActiveKey] = React.useState('detail')
  const [showImportModal, setShowImportModal] = React.useState(false)

  const invalidate = useInvalidate()

  const { data: classData, isLoading } = useOne<
    TCommonResponse<TLMSClass>,
    TCommonError
  >({
    resource: CLASS_RESOURCE,
    dataProviderName: 'lms',
    id,
    queryOptions: {
      enabled: !!programId && !!programLocalId,
    },
  })

  const { mutateAsync: doDelete } = useDelete()

  const { data: programLocalData } = useOne<
    TCommonResponse<TLMSProgramLocal>,
    TCommonError
  >({
    resource: PROGRAM_LOCAL_RESOURCE,
    dataProviderName: 'lms',
    id: programLocalId,
    queryOptions: {
      enabled: !!programLocalId,
    },
  })
  const { setProgram } = useProgramStore((store) => store)

  const { data: programData } = useOne<
    TCommonResponse<TLMSProgramDetail>,
    TCommonError
  >({
    resource: PROGRAM_RESOURCE,
    dataProviderName: 'lms',
    id: programId,
    queryOptions: {
      enabled: !!programId,
      onSuccess: (data) => {
        setProgram({
          isOwner: Boolean(data.data.data.programOwner),
          entityId: data.data.data.entityId,
        })
      },
    },
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<TLMSClass, TCommonError, TLMSClassRequest>({
    action: 'edit',
    resource: CLASS_RESOURCE,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Kelas successfully updated',
      type: 'success',
      description: 'Successful',
    },
    errorNotification: (err) =>
      showErrorNotification(err, 'There was a problem when updating Kelas'),
  })

  const { data: canEdit } = useCan({
    action: 'edit',
    resource: 'lms-class-management',
  })

  const { data: canDelete } = useCan({
    action: 'delete',
    resource: 'lms-class-management',
  })

  const { data: canAccessProgram } = useCan({
    action: 'show',
    resource: 'lms-program-management',
  })

  const { data: canResetCertificateUser } = useCan({
    action: 'show',
    resource: 'reset-certificate-user',
  })

  const { data: canResetCertificateProgram } = useCan({
    action: 'show',
    resource: 'reset-certificate-program',
  })

  const { data: canDeactivateActor } = useCan({
    action: 'field',
    resource: 'lms-class-management',
    params: { field: 'deactivate-actor' },
  })

  const { data: canReactivateActor } = useCan({
    action: 'field',
    resource: 'lms-class-management',
    params: { field: 'reactivate-actor' },
  })

  const { data: canMigrateActor } = useCan({
    action: 'field',
    resource: 'lms-class-management',
    params: { field: 'migrate-actor' },
  })

  const { data: canAddActor } = useCan({
    action: 'field',
    resource: 'lms-class-management',
    params: { field: 'additional-actor' },
  })

  if (!programId || !programLocalId)
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    )

  const programStatus = programData?.data?.data.status

  return (
    <>
      <Show
        title="Kelas Detail"
        resource={CLASS_RESOURCE}
        isLoading={isLoading}
        canDelete={false}
        breadcrumb={
          <NestedBreadcrumb
            injectedItems={[
              ...(canAccessProgram?.can
                ? [
                    {
                      label: 'Program',
                      href: listUrl('lms-program-management'),
                    },
                  ]
                : []),
              {
                label: 'Program Lokal',
                href: canAccessProgram?.can
                  ? showUrl('lms-program-management', programId) +
                    `?activeTab=programLocal`
                  : listUrl('lms-program-local-management'),
              },
              {
                label: 'Kelas',
                href:
                  showUrl('lms-program-local-management', programLocalId) +
                  `?programId=${programId}&defaultTab=kelas`,
              },
              {
                label: 'Show',
              },
            ]}
          />
        }
        headerButtons={() => (
          <Space>
            {activeKey === 'detail' && (
              <>
                <EditButton
                  onClick={() => {
                    editShow(id)
                  }}
                  disabled={
                    !canEdit?.can ||
                    programLocalData?.data.data.status ===
                      PROGRAM_LOCAL_STATE.PUBLISHED
                  }
                >
                  Edit Kelas
                </EditButton>
                <PopDeleteConfirm
                  title={`Are you sure want to delete Kelas "${classData?.data.data.name}"?`}
                  placeholder="Type Kelas name to confirm"
                  errorMessage="Kelas Name did not match"
                  placement="bottomRight"
                  onConfirm={() =>
                    doDelete(
                      {
                        id,
                        resource: CLASS_RESOURCE,
                        dataProviderName: 'lms',
                        successNotification: {
                          message: 'Kelas successfully deleted',
                          type: 'success',
                          description: 'Successful',
                        },
                      },
                      {
                        onSuccess: () => goBack(),
                      },
                    )
                  }
                  okButtonProps={{
                    danger: true,
                    type: 'default',
                  }}
                  okText="Delete"
                  validateValue={classData?.data.data.name!}
                  disabled={
                    !canDelete?.can ||
                    programLocalData?.data.data.status ===
                      PROGRAM_LOCAL_STATE.PUBLISHED
                  }
                >
                  <Button
                    danger
                    icon={<Icons.DeleteOutlined />}
                    disabled={
                      !canDelete?.can ||
                      programLocalData?.data.data.status ===
                        PROGRAM_LOCAL_STATE.PUBLISHED
                    }
                  >
                    Delete Kelas
                  </Button>
                </PopDeleteConfirm>
              </>
            )}
          </Space>
        )}
      >
        <Head>
          <title>LMS Admin | {options?.label} </title>
        </Head>
        <Modal
          title="Tambah Partisipan"
          visible={showImportModal}
          onCancel={() => setShowImportModal(false)}
          className="upload-or-select-modal"
          destroyOnClose
          footer={null}
        >
          <CreateParticipantSection
            onCancel={() => setShowImportModal(false)}
            customExampleUrl={`${process.env.BASE_PATH}/api/sample/csv-file?file=csv-peserta-kelas`}
            onSuccess={() => {
              invalidate({
                resource: `programs/${programId}/local-programs/${programLocalId}/classes/${id}/participants`,
                dataProviderName: 'lms',
                invalidates: ['list'],
              })
            }}
            customSubmitResources={{
              resource: `programs/${programId}/local-programs/${programLocalId}/classes/${id}/participants/import`,
            }}
          />
        </Modal>

        <Tabs
          defaultActiveKey="detail"
          activeKey={activeKey}
          onChange={setActiveKey}
          destroyInactiveTabPane
        >
          <Tabs.TabPane tab="Detail" key="detail">
            <TabKelasDetail data={classData?.data.data} />
            <Divider style={{ marginBottom: 0 }} />
            <div className="mt-4">
              <div className="flex flex-row items-center justify-between mb-4">
                <p className="text-lg font-bold">Peserta Kelas</p>

                <Button
                  type="ghost"
                  icon={<Icons.UploadOutlined />}
                  onClick={() => setShowImportModal(true)}
                  disabled={programStatus !== PROGRAM_STATE.PREPARATION}
                >
                  Import Peserta
                </Button>
              </div>
              <TableClassParticipant
                disableUnassign={
                  programLocalData?.data.data.status ===
                  PROGRAM_LOCAL_STATE.PUBLISHED
                }
                disableDeactivate={Boolean(
                  programStatus !== PROGRAM_STATE.RUNNING ||
                    !canDeactivateActor?.can,
                )}
                disableReactivate={Boolean(
                  programStatus !== PROGRAM_STATE.RUNNING ||
                    !canReactivateActor?.can,
                )}
                disableMigrate={Boolean(
                  programStatus !== PROGRAM_STATE.RUNNING ||
                    !canMigrateActor?.can,
                )}
                disableAddition={
                  programStatus !== PROGRAM_STATE.RUNNING || !canAddActor?.can
                }
                showResetCertificateButton={
                  programData?.data?.data?.useNewCertificate
                    ? canResetCertificateProgram?.can
                    : canResetCertificateUser?.can
                }
                showMigrationButton={
                  programStatus === PROGRAM_STATE.RUNNING &&
                  process.env
                    .NEXT_PUBLIC_ENABLE_REACTIVATE_DEACTIVATE_PARTICIPANT ==
                    'true'
                }
                localProgram={{
                  id: String(programLocalId),
                  name: programLocalData?.data?.data?.name || '',
                }}
                participantClass={{
                  id: String(id),
                  name: classData?.data?.data?.name || '',
                }}
                useNewCertificate={programData?.data?.data?.useNewCertificate}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Kelompok Murid" key="kelompok-murid">
            <TabKelompokMurid
              programId={programId}
              programLocalId={programLocalId}
              classId={id}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Nilai Peserta" key="grades-peserta">
            <TableClassParticipant tableMode="grading" />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Pool Partisipan" key="alloc-peserta">
            <TableParticipantAllocation
              pageType="PROGRAM_LOKAL_KELAS_LIST"
              disableAllocation={
                programLocalData?.data.data.status !== PROGRAM_LOCAL_STATE.DRAFT
              }
            />
          </Tabs.TabPane>
        </Tabs>
      </Show>

      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={() => {
          editFormProps.form?.resetFields()
          editClose()
        }}
      />
    </>
  )
}
