import { Button, Icons, Popconfirm, Space, Table } from '@pankod/refine-antd'
import { useTable } from '@pankod/refine-antd'
import { useCan, useCreate } from '@pankod/refine-core'
import React from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { TCommonResponse } from 'src/interfaces/common'
import { LMSWebinarInstructor } from '../../types'

type InstructorTable = {
  programId?: string | number
  useNewCertificate?: boolean
}

const InstructorTable = ({ programId, useNewCertificate }: InstructorTable) => {
  const { tableProps } = useTable<TCommonResponse<LMSWebinarInstructor>>({
    dataProviderName: 'lms',
    resource: `programs/${programId}/instructors`,
  })
  const { mutateAsync: doReset } = useCreate()
  const { data: canResetCertificateProgram } = useCan({
    action: 'show',
    resource: 'reset-certificate-program',
  })
  return (
    <Table
      {...tableProps}
      rowKey={'userId'}
      columns={[
        {
          title: 'User ID',
          dataIndex: 'userId',
        },
        {
          title: 'Nama',
          dataIndex: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
        },
        ...(canResetCertificateProgram?.can && useNewCertificate
          ? [
              {
                // @ts-expect-error
                render: (_, record) => {
                  return (
                    <Space>
                      <Popconfirm
                        title="Apakah Anda yakin ingin reset sertifikat untuk instruktur ini?"
                        okButtonProps={{ danger: true }}
                        okText="Reset"
                        cancelText="Batal"
                        placement="bottomRight"
                        onConfirm={async () => {
                          return await doReset({
                            dataProviderName: 'lms',
                            resource: `v1/programs/${programId}/user/${record.userId}/reset-certificate`,
                            values: {},
                            successNotification: {
                              message: 'Sertifikat berhasil direset',
                              type: 'success',
                              description: 'Sukses',
                            },
                            errorNotification: (e) =>
                              showErrorNotification(
                                e,
                                'Terdapat gangguan saat reset sertifikat',
                              ),
                          })
                        }}
                        disabled={!canResetCertificateProgram?.can}
                      >
                        <Button
                          icon={<Icons.FileSyncOutlined />}
                          block
                          danger
                          size="small"
                          disabled={!canResetCertificateProgram?.can}
                        >
                          Reset Sertifikat
                        </Button>
                      </Popconfirm>
                    </Space>
                  )
                },
              },
            ]
          : []),
      ]}
    />
  )
}

export default InstructorTable
