import {
  Button,
  DateField,
  Icons,
  Modal,
  notification,
  Table,
  Tag,
  TextField,
  useTable,
} from '@pankod/refine-antd'
import React from 'react'

import { getGuruToken } from 'src/helpers/session'

type DownloadLaporanListModal = {
  visible?: boolean
  onClose?: () => void
  programId?: string | string[] | number
}

const DownloadLaporanListModal = ({
  visible,
  onClose,
  programId,
}: DownloadLaporanListModal) => {
  const { tableProps, tableQueryResult } = useTable({
    dataProviderName: 'lms',
    resource: `programs/${programId}/program-attachment`,
    queryOptions: {
      enabled: visible,
    },
  })
  return (
    <Modal
      visible={visible}
      onCancel={() => {
        onClose?.()
      }}
      width={1000}
      maskClosable={false}
      destroyOnClose
      title="Daftar Antrian Unduhan Laporan Program"
      footer={
        <Button
          onClick={() => {
            onClose?.()
          }}
        >
          Kembali
        </Button>
      }
    >
      <Table
        {...tableProps}
        size="small"
        rowKey={'id'}
        scroll={{ x: 1200 }}
        footer={() => (
          <div className="flex justify-end">
            <Button
              size="small"
              icon={<Icons.SyncOutlined spin={tableQueryResult.isRefetching} />}
              onClick={() => {
                tableQueryResult.refetch()
              }}
              disabled={tableQueryResult.isFetching}
            >
              Refresh
            </Button>
          </div>
        )}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
            width: 100,
          },
          {
            title: 'Jenis Laporan',
            dataIndex: 'type',
            width: 250,
          },
          {
            title: 'Informasi Laporan',
            dataIndex: 'notes',
            width: 250,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            width: 120,
            align: 'center',
            render: (value) => {
              if (value === 'completed')
                return (
                  <Tag color="success" icon={<Icons.CheckOutlined />}>
                    Selesai
                  </Tag>
                )
              return (
                <Tag
                  color="processing"
                  icon={<Icons.LoadingOutlined spin={value === 'processing'} />}
                >
                  Diproses
                </Tag>
              )
            },
          },
          {
            title: 'Created At',
            dataIndex: 'createdAt',
            render: (value) => (
              <DateField value={value} format="YYYY-MM-DD HH:mm:ss" />
            ),
            width: 150,
          },
          {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            render: (value) => (
              <DateField value={value} format="YYYY-MM-DD HH:mm:ss" />
            ),
            width: 150,
          },
          {
            title: 'Last Processed',
            dataIndex: 'lastProcessedAt',
            render: (value) =>
              value ? (
                <DateField value={value} format="YYYY-MM-DD HH:mm:ss" />
              ) : (
                <TextField value={'Tidak ada data'} italic type="secondary" />
              ),
            width: 150,
          },
          {
            fixed: 'right',
            width: 100,
            render: (_, record) => {
              return (
                <Button
                  icon={<Icons.DownloadOutlined />}
                  onClick={async () => {
                    try {
                      const response = await fetch(
                        `${process.env.NEXT_PUBLIC_LMS_API_URL}/programs/${programId}/program-attachment/${record.id}`,
                        {
                          headers: {
                            Authorization: `Bearer ${getGuruToken()}`,
                          },
                        },
                      )
                      if (response.status === 404) {
                        throw new Error()
                      }
                      const data = await response.json()
                      const a = document.createElement('a')
                      a.setAttribute('href', data.attachmentUrl)
                      a.setAttribute(
                        'download',
                        `export_laporan_${record.type}_programId_${programId}.csv`,
                      )
                      a.click()
                    } catch (e) {
                      notification.error({
                        message: 'Error',
                        description:
                          'Laporan tidak tersedia atau masih diproses.',
                      })
                    }
                  }}
                  size="small"
                  disabled={record.status !== 'completed'}
                >
                  Unduh
                </Button>
              )
            },
          },
        ]}
      />
    </Modal>
  )
}

export default DownloadLaporanListModal
