function componentToHex(c: number) {
  var hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}

function rgbToHex(rgbString: string) {
  const regExp = /\(([^)]+)\)/
  const matches = regExp.exec(rgbString)
  if (!matches?.[1]) return '#000000'
  const [r, g, b] = matches[1].split(',').map((value) => Number(value))
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 }
}

export { rgbToHex, hexToRgb }
