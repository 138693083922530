import {
  AntdList,
  Button,
  Divider,
  Drawer,
  Icons,
  Popconfirm,
  Space,
  Table,
  TableColumnGroupType,
  TableColumnType,
  Tag,
  useModalForm,
  useTable,
} from '@pankod/refine-antd'
import _ from 'lodash'
import React, { useCallback, useState } from 'react'
import { useDelete } from '@pankod/refine-core'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { kapabilitasMapper } from '@components/DataTableParticipants/blocks/utils'
import { dateToStringFmt } from 'src/helpers/date'
import { UmpanBalikProgramRequest, UmpanBalikProgramResponse } from './types'
import { TCommonError, TLMSCommonError } from 'src/interfaces/common'
import ModalForm from './ModalForm'
import { PROGRAM_STATE } from '@resources/lms-management/program/types'
import PeriodeModalForm, { usePeriodeModalForm } from './PeriodeModalForm'

export const useUmpanBalikProgramDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
    programName: string
  }>({ show: false, programId: '', programName: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string, name: string) => {
      setShowId({ show: true, programId: id, programName: name })
    },
    visible: !!showId.show,
    id: showId.programId,
    name: showId.programName,
    onClose,
  }
}

type UmpanBalikProgramDrawerProps = {
  visible: boolean
  id: number | string
  onClose: () => void
  name: string
  isFinalized: boolean
  programStatus?: PROGRAM_STATE
}
const UmpanBalikProgramDrawer = ({
  id,
  name,
  onClose,
  visible,
  isFinalized,
  programStatus,
}: UmpanBalikProgramDrawerProps) => {
  const shouldDisableAction = programStatus !== PROGRAM_STATE.PREPARATION
  const { mutateAsync: doDelete } = useDelete()

  const { tableProps } = useTable<UmpanBalikProgramResponse, TCommonError>({
    resource: `programs/${id}/umpan-balik`,
    dataProviderName: 'lms',
    queryOptions: { enabled: visible && !!id },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createShow,
    close: createClose,
  } = useModalForm<
    UmpanBalikProgramResponse,
    TLMSCommonError,
    UmpanBalikProgramRequest
  >({
    action: 'create',
    resource: `programs/${id}/umpan-balik`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Umpan balik berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating umpan balik',
      ),
  })

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editShow,
    close: editClose,
  } = useModalForm<
    UmpanBalikProgramResponse,
    TLMSCommonError,
    UmpanBalikProgramRequest
  >({
    action: 'edit',
    resource: `programs/${id}/umpan-balik`,
    dataProviderName: 'lms',
    autoResetForm: true,
    redirect: false,
    successNotification: {
      message: 'Umpan balik berhasil dibuat',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when updating umpan balik',
      ),
  })

  const { show: showPeriodeModalForm, ...periodeModalFormProps } =
    usePeriodeModalForm()

  const columns: (
    | TableColumnType<UmpanBalikProgramResponse>
    | TableColumnGroupType<UmpanBalikProgramResponse>
  )[] = [
    { title: 'ID', dataIndex: 'id', key: '_id' },
    { title: 'Nama Umpan Balik', dataIndex: 'name', key: '_name' },
    {
      title: 'Form Umpan Balik',
      dataIndex: ['form', 'title'],
      key: '_formTitle',
    },
    {
      title: 'Aktor Pengisi',
      dataIndex: 'evaluatorRoles',
      key: '_evaluatorRoles',
      render: (value) => (
        <AntdList
          dataSource={value}
          size="small"
          renderItem={(item: string, i) => (
            <AntdList.Item key={i} className="!px-0">
              {_.startCase(
                kapabilitasMapper(item, (item) =>
                  item === 'self' ? 'diri sendiri' : item,
                ),
              )}
            </AntdList.Item>
          )}
        />
      ),
    },
    {
      title: 'Periode Pengisian',
      key: '_periode',
      children: [
        {
          title: 'Mulai',
          key: '_start',
          dataIndex: 'startDate',
          render: (value) => dateToStringFmt(value, 'DD MMM YYYY'),
        },
        {
          title: 'Selesai',
          key: '_end',
          dataIndex: 'endDate',
          render: (value) => dateToStringFmt(value, 'DD MMM YYYY'),
        },
      ],
    },
    {
      key: '_action',
      render: (_, record) => (
        <Space direction="vertical">
          {isFinalized && (
            <Button
              size="small"
              icon={<Icons.FieldTimeOutlined />}
              onClick={() => {
                showPeriodeModalForm(record.id)
              }}
            >
              Ubah Periode
            </Button>
          )}
          <Button
            block
            size="small"
            icon={<Icons.EditOutlined />}
            disabled={isFinalized || shouldDisableAction}
            onClick={() => editShow(record.id)}
          >
            Ubah
          </Button>
          <Popconfirm
            title="Apakah Anda yakin ingin menghapus umpan balik ini?"
            okText="Hapus"
            cancelText="Batal"
            okButtonProps={{ danger: true }}
            disabled={isFinalized || shouldDisableAction}
            placement="topRight"
            onConfirm={async () =>
              doDelete({
                id: record.id,
                resource: `programs/${id}/umpan-balik`,
                dataProviderName: 'lms',
                invalidates: ['list'],
                successNotification: {
                  message: 'Umpan balik berhasil dihapus',
                  type: 'success',
                  description: 'Sukses',
                },
                errorNotification: (error: any) =>
                  showErrorNotification(
                    error,
                    'There was a problem when removing umpan balik',
                  ),
              })
            }
          >
            <Button
              block
              size="small"
              danger
              icon={<Icons.DeleteOutlined />}
              disabled={isFinalized || shouldDisableAction}
            >
              Hapus
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  return (
    <Drawer
      title={`Daftar Umpan Balik Program | ${name}`}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      extra={
        <Space>
          Status:
          <Tag color={isFinalized ? 'processing' : 'default'}>
            {isFinalized ? 'FINALIZED' : 'DRAFT'}
          </Tag>
          <Divider type="vertical" />
          <Button
            type="primary"
            icon={<Icons.PlusOutlined />}
            onClick={() => {
              createShow()
            }}
            disabled={isFinalized || shouldDisableAction}
          >
            Tambah Umpan Balik
          </Button>
        </Space>
      }
    >
      <Table {...tableProps} bordered columns={columns} rowKey="id" />
      <ModalForm
        formProps={createFormProps}
        modalProps={createModalProps}
        mode="create"
        onClose={createClose}
      />
      <ModalForm
        formProps={editFormProps}
        modalProps={editModalProps}
        mode="edit"
        onClose={editClose}
      />
      <PeriodeModalForm {...periodeModalFormProps} programId={id} />
    </Drawer>
  )
}

export default UmpanBalikProgramDrawer
