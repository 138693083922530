import {
  Button,
  ButtonProps,
  Form,
  Icons,
  Modal,
  notification,
  Select,
  useSelect,
} from '@pankod/refine-antd'
import { useCreate } from '@pankod/refine-core'
import React, { useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
type ExportPresensiButtonProps = {
  programId?: string | number
  can?: boolean
  onDaftarUnduhanClick?: () => void
  buttonProps?: ButtonProps
}
const ExportPresensiButton = ({
  programId,
  can,
  onDaftarUnduhanClick,
  buttonProps,
}: ExportPresensiButtonProps) => {
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const programLocalId = Form.useWatch('programLocalId', form)
  const exportType = Form.useWatch('exportType', form)
  const kelasId = Form.useWatch('kelasId', form)

  const { selectProps: selectKelasProps, queryResult: queryKelasResult } =
    useSelect({
      dataProviderName: 'lms',
      resource: `programs/${programId}/classes`,
      queryOptions: { enabled: showModal },
      optionLabel: 'name',
      optionValue: 'id',
      defaultValueQueryOptions: {
        enabled: false,
      },
      filters: [
        {
          field: 'pageSize',
          operator: 'eq',
          value: -1,
        },
      ],
    })
  const {
    selectProps: selectProgramLocalProps,
    queryResult: queryProgramLocalResult,
  } = useSelect({
    dataProviderName: 'lms',
    resource: `programs/${programId}/local-programs`,
    queryOptions: { enabled: showModal },
    optionLabel: 'name',
    optionValue: 'id',
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })

  const { mutateAsync, isLoading } = useCreate()
  return (
    <>
      {can && (
        <Button onClick={() => setShowModal(true)} {...buttonProps}>
          Ekspor Laporan
        </Button>
      )}
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        okButtonProps={{ loading: isLoading }}
        afterClose={() => {
          form.resetFields()
        }}
        onOk={async () => {
          await form.validateFields()

          try {
            await mutateAsync({
              dataProviderName: 'lms',
              resource: `programs/${programId}/program-attachment`,
              values: {
                type: exportType,
                programLocalId:
                  typeof programLocalId === undefined ||
                  programLocalId?.length === 0
                    ? undefined
                    : programLocalId,
                kelasId:
                  typeof kelasId === undefined || kelasId?.length === 0
                    ? undefined
                    : kelasId,
              },
              successNotification: false,
              errorNotification: (error) =>
                showErrorNotification(
                  error,
                  'Terdapat gangguan saat mengunduh laporan',
                ),
            })
            notification.success({
              key: 'download_laporan_umpan_balik',
              duration: 6,
              description: (
                <div>
                  <p>
                    Ekspor/unduh laporan sedang diproses, harap pantau pada menu
                    "Daftar Unduhan Laporan"
                  </p>
                  <Button
                    onClick={() => {
                      notification.close('download_laporan_umpan_balik')
                      onDaftarUnduhanClick?.()
                    }}
                    icon={<Icons.DownloadOutlined />}
                    type="primary"
                  >
                    Daftar Unduhan Laporan
                  </Button>
                </div>
              ),
              type: 'success',
              message: 'Sukses',
            })
            setShowModal(false)
          } catch (e) {
            showErrorNotification(e, 'Terdapat gangguan saat mengunduh laporan')
          }
        }}
        okText="Unduh"
        cancelText="Batal"
        title="Ekspor Laporan Presensi"
      >
        <Form
          form={form}
          layout="vertical"
          validateMessages={{ required: '${label} harus diisi.' }}
        >
          <Form.Item
            name="exportType"
            label="Cakupan Ekspor"
            required
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Pilih cakupan"
              options={[
                {
                  label: 'Rangkuman Presensi',
                  value: 'laporan_presensi_summary',
                },
                {
                  label: 'Detail Presensi',
                  value: 'laporan_presensi_detail',
                },
              ]}
            />
          </Form.Item>

          <Form.Item name="programLocalId" label="Program Lokal">
            <Select
              {...selectProgramLocalProps}
              options={[...(selectProgramLocalProps?.options || [])]}
              loading={queryProgramLocalResult.isFetching}
              onSearch={() => {}}
              placeholder="Pilih program lokal"
              mode="multiple"
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase?.()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item name="kelasId" label="Kelas">
            <Select
              {...selectKelasProps}
              loading={queryKelasResult.isFetching}
              placeholder="Pilih kelas"
              mode="multiple"
              onSearch={() => {}}
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase?.()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ExportPresensiButton
