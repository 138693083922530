import { Canvas, Textbox } from 'fabric'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Bold,
  Italic,
  Underline,
} from 'lucide-react'
import { Button, Input, InputNumber, Select } from '@pankod/refine-antd'
import clsx from 'clsx'

import { CANVAS_CONFIG, CONTROL_CONFIG } from '../constants'
import { hexToRgb, rgbToHex } from './color'

export const addTextbox = async (props: {
  canvas: Canvas | null
  value?: string
  customObjectAlign?: string
}) => {
  if (!props.canvas) return
  let isCustomFontAvailable = false
  try {
    isCustomFontAvailable = document?.fonts.check('italic bold 16px Inter')
  } catch (e) {
    console.error('Load Font Error: ', e)
  }
  const { canvas } = props
  canvas.discardActiveObject()
  const customId = nanoid()
  const text = new Textbox(props.value || 'Ketik tulisan di sini', {
    snapAngle: CONTROL_CONFIG.snapAngle,
    snapThreshold: CONTROL_CONFIG.snapThreshold,
    editable: true,
    textAlign: props.customObjectAlign || 'left',
    customObjectAlign: props.customObjectAlign || 'left',
    customId,
    fontSize: 20,
    customObjectType: 'Textbox',
    left: CANVAS_CONFIG.width / 2 - 100,
    top: CANVAS_CONFIG.height / 2 - 10,
    ...(isCustomFontAvailable ? { fontFamily: 'Inter' } : {}),
  })
  if (!props.value) {
    text.set('width', 200)
  }
  text.setControlsVisibility({
    mt: false,
    mb: false,
  })
  canvas?.add(text)
  canvas?.bringObjectToFront(text)
  canvas?.setActiveObject(text)
  return customId
}

export const buttonDecorationBuilder = (
  props: {
    name: string
    icon: React.ReactNode
    fn: (val: boolean) => string | boolean
    value: string | boolean
  },
  value: string | boolean,
  onChange: (val: string | boolean) => void,
  canvas?: Canvas | null,
) => {
  return (
    <Button
      type={value === props.value ? 'primary' : 'ghost'}
      icon={props.icon}
      onClick={() => {
        canvas
          ?.getActiveObject()
          ?.set(props.name, props.fn(value !== props.value))
        canvas?.requestRenderAll()
        onChange(props.fn(value !== props.value))
      }}
    />
  )
}

export const fontDecoration = {
  bold: {
    name: 'fontWeight',
    icon: <Bold className="h-5 w-5 mt-[3px]" />,
    fn: (inactive: boolean) => (inactive ? 'bold' : 'normal'),
    value: 'bold',
  },
  italic: {
    name: 'fontStyle',
    icon: <Italic className="h-5 w-5 mt-[3px]" />,
    fn: (inactive: boolean) => (inactive ? 'italic' : 'normal'),
    value: 'italic',
  },
  underline: {
    name: 'underline',
    icon: <Underline className="h-5 w-5 mt-[3px]" />,
    fn: (val: boolean) => val,
    value: true,
  },
  textAlignLeft: {
    name: 'textAlign',
    icon: <AlignLeft className="h-5 w-5 mt-[3px]" />,
    fn: () => 'left',
    value: 'left',
  },
  textAlignCenter: {
    name: 'textAlign',
    icon: <AlignCenter className="h-5 w-5 mt-[3px]" />,
    fn: () => 'center',
    value: 'center',
  },
  textAlignRight: {
    name: 'textAlign',
    icon: <AlignRight className="h-5 w-5 mt-[3px]" />,
    fn: () => 'right',
    value: 'right',
  },
}

export const TextboxConfig = (props: { canvas: Canvas | null }) => {
  const { canvas } = props
  const [textBoxProperty, setTextBoxProperty] = useState(() => {
    const property = {
      fontWeight: canvas?.getActiveObject()?.get('fontWeight'),
      fontStyle: canvas?.getActiveObject()?.get('fontStyle'),
      underline: canvas?.getActiveObject()?.get('underline'),
      textAlign: canvas?.getActiveObject()?.get('textAlign'),
      fontSize: canvas?.getActiveObject()?.get('fontSize'),
      fontFamily: canvas?.getActiveObject()?.get('fontFamily'),
      fill: canvas?.getActiveObject()?.get('fill'),
    }
    return property
  })
  const disableColorPicker = ['grade'].includes(
    canvas?.getActiveObject()?.customVariableKey || '',
  )

  return (
    <div className="grid grid-flow-row gap-1">
      {canvas?.getActiveObject()?.customObjectType === 'Text' && (
        <Button.Group>
          {buttonDecorationBuilder(
            { ...fontDecoration['textAlignLeft'] },
            textBoxProperty.textAlign,
            (value) => {
              setTextBoxProperty((state) => ({ ...state, textAlign: value }))
            },
            canvas,
          )}
          {buttonDecorationBuilder(
            { ...fontDecoration['textAlignCenter'] },
            textBoxProperty.textAlign,
            (value) => {
              setTextBoxProperty((state) => ({ ...state, textAlign: value }))
            },
            canvas,
          )}
          {buttonDecorationBuilder(
            { ...fontDecoration['textAlignRight'] },
            textBoxProperty.textAlign,
            (value) => {
              setTextBoxProperty((state) => ({ ...state, textAlign: value }))
            },
            canvas,
          )}
        </Button.Group>
      )}
      <Button.Group>
        {buttonDecorationBuilder(
          { ...fontDecoration['italic'] },
          textBoxProperty.fontStyle,
          (value) => {
            setTextBoxProperty((state) => ({ ...state, fontStyle: value }))
          },
          canvas,
        )}
        {buttonDecorationBuilder(
          { ...fontDecoration['underline'] },
          textBoxProperty.underline,
          (value) => {
            setTextBoxProperty((state) => ({ ...state, underline: value }))
          },
          canvas,
        )}
      </Button.Group>
      <div className="ant-input-number-wrapper ant-input-number-group">
        <div className="ant-input-number-group-addon">Weight</div>
        <Select
          value={textBoxProperty['fontWeight']}
          className="w-full"
          options={[
            { label: <div className="font-thin">Thin</div>, value: 100 },
            {
              label: <div className="font-extralight">Extra Light</div>,
              value: 200,
            },
            { label: <div className="font-light">Light</div>, value: 300 },
            {
              label: <div className="font-normal">Normal</div>,
              value: 'normal',
            },
            { label: <div className="font-medium">Medium</div>, value: 500 },
            {
              label: <div className="font-semibold">Semi Bold</div>,
              value: 600,
            },
            { label: <div className="font-bold">Bold</div>, value: 700 },
            {
              label: <div className="font-extrabold">Extra Bold</div>,
              value: 800,
            },
            { label: <div className="font-black">Black</div>, value: 900 },
          ]}
          onChange={(value) => {
            canvas?.getActiveObject()?.set('fontWeight', value)
            canvas?.requestRenderAll()
            setTextBoxProperty((state) => ({ ...state, fontWeight: value }))
          }}
        />
      </div>
      <InputNumber
        addonBefore="Size"
        step={1}
        value={textBoxProperty['fontSize']}
        min={8}
        max={80}
        onChange={(value) => {
          canvas?.getActiveObject()?.set('fontSize', value)
          canvas?.requestRenderAll()
          setTextBoxProperty((state) => ({
            ...state,
            fontSize: Math.min(Math.max(value, 8), 80),
          }))
        }}
      />

      <div
        className={clsx(
          'ant-input-number-wrapper ant-input-number-group',
          disableColorPicker && 'opacity-40 cursor-not-allowed',
        )}
      >
        <div className={'ant-input-number-group-addon'}>Color</div>
        <Input
          value={rgbToHex(textBoxProperty['fill'])}
          type="color"
          disabled={disableColorPicker}
          onChange={(e) => {
            const value = e.target.value
            const { r, g, b } = hexToRgb(value)
            const rgbString = `rgb(${r},${g},${b})`
            canvas?.getActiveObject()?.set('fill', rgbString)
            canvas?.requestRenderAll()
            setTextBoxProperty((state) => ({
              ...state,
              fill: rgbString,
            }))
          }}
        />
      </div>
    </div>
  )
}
