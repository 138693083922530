import React from 'react'
import {
  useTable,
  Table,
  TextField,
  Space,
  ShowButton,
  Button,
  Tag,
  Descriptions,
  Popover,
  Empty,
  Icons,
  Popconfirm,
  // Icons,
  // Popconfirm,
  // Form,
  // Select,
} from '@pankod/refine-antd'
import {
  IResourceComponentsProps,
  GetListResponse,
  CrudFilters,
  useDelete,
  useOne,
  useCreate,
  useInvalidate,
} from '@pankod/refine-core'
import { useRouter } from 'next/router'
import { DeleteOutlined } from '@ant-design/icons'
import Link from 'next/link'

import { TCommonError } from 'src/interfaces/common'
import { TFilterForm } from '@components/ContentFilter'
import { ParticipantDetails } from './blocks/ParticipantDetails'
import { SideFilterParticipantForm } from './blocks/SideFilterParticipantForm'
import { WithParticipantFilterContext } from 'src/hooks/context/useParticipantFilterOptionsContext'
import { TProgramGradeData } from '@resources/lms-management/program/types'
import { kapabilitasMapper, successMigrateNotification } from './blocks/utils'
import { PopDeleteConfirm } from '@resources/lms-management/components/PopDeleteConfirm'
import ActorMigrationModal, {
  useActorMigrationModal,
} from './blocks/ActorMigrationModal'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import ActorAdditionModal, {
  useActorAdditionModal,
} from './blocks/ActorAdditionModal'

import type { TLMSParticipant } from './types'

// const ROLE_KELAS_OPTIONS = [
//   {
//     label: 'instruktur',
//     value: 'instruktur',
//   },
//   {
//     label: 'asisten',
//     value: 'asisten',
//   },
//   {
//     label: 'peserta',
//     value: 'peserta',
//   },
// ]

type TTableParticipantListProps = {
  tableMode?: string
  disableUnassign?: boolean
  disableDeactivate?: boolean
  disableReactivate?: boolean
  disableAddition?: boolean
  disableMigrate?: boolean
  showMigrationButton?: boolean
  showResetCertificateButton?: boolean
  localProgram?: {
    id: string
    name: string
  }
  participantClass?: {
    id: string
    name: string
  }
  useNewCertificate?: boolean
}

export const TableClassParticipant: React.FC<
  IResourceComponentsProps<GetListResponse<any>> & TTableParticipantListProps
> = ({
  tableMode,
  disableUnassign = false,
  disableDeactivate = true,
  disableReactivate = true,
  disableMigrate = true,
  disableAddition = true,
  showMigrationButton = false,
  showResetCertificateButton = false,
  localProgram,
  participantClass,
  useNewCertificate,
}) => {
  const router = useRouter()
  const {
    participantEmail = null,
    mode = 'view',
    programId = '',
    programLocalId = '',
    id = '',
  } = router.query
  const isGradingMode = tableMode === 'grading'

  const { data: grades } = useOne<TProgramGradeData>({
    resource: `programs/${programId}`,
    id: 'grades',
    dataProviderName: 'lms',
    queryOptions: {
      enabled: !!programId && isGradingMode,
    },
  })

  const actualProgramId = !!programId ? programId : id
  const programLocalResourceURL =
    programId && !programLocalId
      ? `local-programs/${id}/participants`
      : 'participants'

  const actualResourceURL =
    programId !== '' && programLocalId !== ''
      ? `local-programs/${programLocalId}/classes/${id}/participants`
      : `${programLocalResourceURL}`

  const { mutateAsync: unassignParticipant } = useDelete()

  const { show: showMigration, ...actorMigrationModalProps } =
    useActorMigrationModal({
      classId: String(id),
      localProgramId: String(programLocalId),
      programId: String(programId),
    })

  const { show: showAddition, ...actorAdditionModalProps } =
    useActorAdditionModal({
      classId: String(id),
      localProgramId: String(programLocalId),
      programId: String(programId),
    })

  const tablePermanentFilter: CrudFilters = isGradingMode
    ? [
        {
          field: 'roleKelas',
          operator: 'eq',
          value: 'peserta',
        },
      ]
    : []

  const { tableProps, searchFormProps, tableQueryResult } = useTable<
    TLMSParticipant,
    TCommonError,
    TFilterForm<any>
  >({
    resource: `programs/${actualProgramId}/${actualResourceURL}`,
    dataProviderName: 'lms',
    permanentFilter: tablePermanentFilter,
    queryOptions: {
      enabled: programId !== '' && programLocalId !== '',
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      const formParams = params
      const { searchMode = 'name' } = params

      const constructFilters = (key: string, value: any) => {
        if (key === 'searchMode') return

        if (key === 'searchQuery') {
          filters.push({
            field: searchMode === 'name' ? 'email' : 'name',
            operator: 'eq',
            value: '',
          })

          filters.push({
            field: searchMode,
            operator: 'eq',
            value,
          })

          return
        }

        filters.push({
          field: key,
          operator: 'eq',
          value,
        })
      }

      Object.entries(formParams).forEach(([key, value]) => {
        constructFilters(key, value)
      })

      return filters
    },
  })

  const { mutate: doDeactivate } = useCreate()
  const { mutateAsync: doReset } = useCreate()
  const invalidate = useInvalidate()

  const handleParticipantDetailShowClick = (
    email: string,
    mode: string = 'view',
  ) => {
    router.replace({
      pathname: '/[resource]/[action]/[id]',
      query: {
        ...router.query,
        participantEmail: email,
        mode,
      },
    })
  }

  const handleUnassignParticipant = (userId: string) => {
    unassignParticipant({
      resource: `programs/${actualProgramId}/${actualResourceURL}`,
      dataProviderName: 'lms',
      metaData: {
        payload: {
          userId,
        },
      },
      id: '',
      successNotification: (e: any) => {
        return {
          message: 'Success',
          description: e.data.message,
          type: 'success',
        }
      },
      errorNotification: (errorRes: any) => {
        const responseErr = errorRes?.response?.data?.error?.message || ''

        return {
          message: responseErr,
          description: 'Failed',
          type: 'error',
        }
      },
    })
  }

  const handleInvalidateParticipantList = () => {
    invalidate({
      resource: `programs/${actualProgramId}/${actualResourceURL}`,
      dataProviderName: 'lms',
      invalidates: ['list'],
    })
  }

  const getGradesLabelByRange = (userGrade: number) => {
    const grade = grades?.data.data.find((grade) => {
      return userGrade >= grade.from && userGrade <= grade.to
    })

    return grade?.label
  }

  const handleGoToCanvasURL = (canvasURL: string) => {
    window.open(canvasURL, '_blank')?.focus()
  }

  const handleGoToGradebookLMS = (canvasURL: string) => {
    const REGEX = /.*\/courses\/([0-9]+)/
    const matched = canvasURL.match(REGEX)
    if (matched) {
      const baseUrl = matched[0]
      window.open(baseUrl + '/gradebook', '_blank')?.focus()
    }
  }

  const getTextFieldType = (deletedAt?: null | string) =>
    !isGradingMode && !!deletedAt ? 'secondary' : undefined

  return (
    <WithParticipantFilterContext>
      <SideFilterParticipantForm
        programId={actualProgramId as string}
        formInstance={searchFormProps}
        withClassRoleFilter={!isGradingMode}
        searchRowChildren={
          isGradingMode && (
            <Space>
              {tableQueryResult?.data?.data.length && (
                <Button
                  type="link"
                  icon={<Icons.LinkOutlined />}
                  onClick={() =>
                    handleGoToGradebookLMS(
                      tableQueryResult.data.data[0].canvasUrl,
                    )
                  }
                >
                  Lihat Nilai di Canvas
                </Button>
              )}

              <Popover
                content={
                  <>
                    {grades?.data.data.length !== 0 ? (
                      <Descriptions
                        title="Range Nilai"
                        column={1}
                        bordered
                        size="small"
                      >
                        {grades?.data.data.map((grade) => {
                          return (
                            <Descriptions.Item
                              label={grade.label}
                              key={grade.label}
                            >
                              {grade.from} - {grade.to}
                            </Descriptions.Item>
                          )
                        })}
                      </Descriptions>
                    ) : (
                      <Empty description="Tidak ada range nilai">
                        <Link
                          href={`/lms-program-management/show/${programId}?activeTab=gradeSetup`}
                        >
                          <a>
                            <Button type="primary">
                              Buat Range Nilai di Program
                            </Button>
                          </a>
                        </Link>
                      </Empty>
                    )}
                  </>
                }
                placement="bottom"
                trigger="click"
              >
                <Button className="mr-4" type="link">
                  Lihat Range Nilai
                </Button>
              </Popover>
            </Space>
          )
        }
      >
        <div className={`${participantEmail && 'flex flex-row'} `}>
          <div className={`${participantEmail && 'w-[53vw] overflow-x-auto'}`}>
            <Table<TLMSParticipant>
              {...tableProps}
              onRow={(record) => ({
                title: !!record.deletedAt ? 'Peserta sudah dinonaktifkan' : '',
              })}
              rowKey="userId"
              rowClassName={(record) =>
                !!record.deletedAt ? 'italic cursor-not-allowed' : ''
              }
              scroll={{ y: 600, x: 900 }}
              footer={() =>
                showMigrationButton ? (
                  <Button
                    disabled={disableAddition}
                    onClick={() => {
                      showAddition()
                    }}
                    icon={<Icons.UserAddOutlined />}
                    type="primary"
                  >
                    Tambah Peserta
                  </Button>
                ) : null
              }
            >
              <Table.Column<TLMSParticipant>
                width={150}
                dataIndex="userId"
                title="User ID"
                fixed="left"
                render={(value, record) => (
                  <TextField
                    type={getTextFieldType(record.deletedAt)}
                    value={value}
                  />
                )}
              />
              <Table.Column<TLMSParticipant>
                width={180}
                dataIndex="email"
                title="E-mail"
                render={(value, record) => (
                  <TextField
                    type={getTextFieldType(record.deletedAt)}
                    value={value}
                  />
                )}
              />
              <Table.Column<TLMSParticipant>
                dataIndex="name"
                title="Nama"
                render={(value, record) => (
                  <TextField
                    value={value}
                    type={getTextFieldType(record.deletedAt)}
                  />
                )}
              />
              <Table.Column<TLMSParticipant>
                dataIndex="participantType"
                title="Tipe"
                render={(value, record) => (
                  <TextField
                    value={value}
                    type={getTextFieldType(record.deletedAt)}
                  />
                )}
              />
              {!isGradingMode ? (
                <>
                  <Table.Column<TLMSParticipant>
                    dataIndex="provinsi"
                    title="Provinsi"
                    render={(value, record) => (
                      <TextField
                        value={value}
                        type={getTextFieldType(record.deletedAt)}
                      />
                    )}
                  />

                  <Table.Column<TLMSParticipant>
                    dataIndex="roleKelas"
                    title="Peran"
                    render={(value, record) => (
                      <TextField
                        className="capitalize"
                        type={getTextFieldType(record.deletedAt)}
                        value={kapabilitasMapper(value)}
                      />
                    )}
                  />
                </>
              ) : (
                <>
                  <Table.Column
                    dataIndex="finalScore"
                    title="Predikat"
                    render={(value) => (
                      <Tag>
                        <TextField value={getGradesLabelByRange(value)} />
                      </Tag>
                    )}
                  />
                  <Table.Column
                    dataIndex="finalScore"
                    title="Nilai"
                    render={(value) => <TextField value={value} />}
                  />
                </>
              )}
              <Table.Column
                width={200}
                render={(_, record: any) => {
                  const isCanvasUrlAvailable = !!record.canvasUrl
                  return (
                    <Space direction="vertical">
                      <ShowButton
                        block
                        size="small"
                        onClick={() => {
                          handleParticipantDetailShowClick(record.email)
                        }}
                        disabled={
                          record.email === participantEmail && mode === 'view'
                        }
                      >
                        Detail User
                      </ShowButton>
                      {/* TODO: disable dlu */}
                      {/* <Popconfirm
                      title={
                        <Form layout="vertical">
                          <p>
                            Ubah Role <b>{record.email}</b>
                          </p>
                          <Form.Item name="roleKelas">
                            <Select defaultValue={record.roleKelas}>
                              {ROLE_KELAS_OPTIONS.map((option) => (
                                <Select.Option
                                  value={option.value}
                                  key={option.value}
                                >
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Form>
                      }
                    >
                      <Button
                        icon={<Icons.EditOutlined />}
                        type="ghost"
                        size="small"
                      >
                        Ubah Role
                      </Button>
                    </Popconfirm> */}
                      {!isGradingMode && showResetCertificateButton && (
                        <Popconfirm
                          title="Apakah Anda yakin ingin reset sertifikat untuk peserta ini?"
                          okButtonProps={{ danger: true }}
                          okText="Reset"
                          cancelText="Batal"
                          placement="topRight"
                          onConfirm={async () => {
                            return await doReset({
                              dataProviderName: 'lms',
                              resource: useNewCertificate
                                ? `v1/programs/${actualProgramId}/user/${record.userId}/reset-certificate`
                                : `v2/programs/${programId}/kelas/${id}/user/${record.userId}/reset-certificate`,
                              values: {},
                              successNotification: {
                                message: 'Sertifikat berhasil direset',
                                type: 'success',
                                description: 'Sukses',
                              },
                              errorNotification: (e) =>
                                showErrorNotification(
                                  e,
                                  'Terdapat gangguan saat reset sertifikat',
                                ),
                            })
                          }}
                        >
                          <Button
                            icon={<Icons.FileSyncOutlined />}
                            block
                            danger
                            size="small"
                          >
                            Reset Sertifikat
                          </Button>
                        </Popconfirm>
                      )}
                      {!isGradingMode && !disableUnassign ? (
                        <Button
                          block
                          size="small"
                          onClick={() => {
                            handleUnassignParticipant(record.userId)
                          }}
                          danger
                          icon={<DeleteOutlined />}
                        >
                          Unassign
                        </Button>
                      ) : (
                        <Button
                          block
                          size="small"
                          onClick={() => {
                            handleGoToCanvasURL(record.canvasUrl)
                          }}
                          icon={<Icons.LinkOutlined />}
                          disabled={
                            !isCanvasUrlAvailable ||
                            (record.email === participantEmail &&
                              mode === 'view')
                          }
                        >
                          {isCanvasUrlAvailable ? (
                            'Lihat Canvas'
                          ) : (
                            <i className="ml-2">Grade Belum Tersedia</i>
                          )}
                        </Button>
                      )}

                      {!isGradingMode && showMigrationButton && (
                        <>
                          <Button
                            block
                            size="small"
                            icon={<Icons.UserSwitchOutlined />}
                            onClick={() =>
                              showMigration({
                                userId: record.userId,
                                userName: record.name,
                                userEmail: record.email,
                                userRole: record.roleKelas,
                              })
                            }
                            disabled={disableMigrate || !!record.deletedAt}
                          >
                            Pindahkan
                          </Button>
                          {!record.deletedAt ? (
                            <PopDeleteConfirm
                              title="Apakah Anda yakin ingin menonaktifkan peserta ini?"
                              validateValue={record.email}
                              placeholder="Tulis ulang email peserta"
                              disabled={disableDeactivate}
                              okButtonProps={{ danger: true }}
                              okText="Nonaktifkan"
                              cancelText="Batal"
                              placement="topRight"
                              errorMessage="Email user tidak sesuai."
                              onConfirm={() => {
                                doDeactivate(
                                  {
                                    resource: `programs/${programId}/local-programs/${programLocalId}/classes/${id}/participants/deactivate`,
                                    values: {
                                      userId: record.userId,
                                    },
                                    successNotification: false,
                                    errorNotification: (err) =>
                                      showErrorNotification(
                                        err,
                                        'Terdapat gangguan ketika menonaktifkan peserta',
                                      ),
                                  },
                                  {
                                    onSettled() {
                                      handleInvalidateParticipantList()
                                    },
                                    onSuccess() {
                                      successMigrateNotification({
                                        type: 'deactivate',
                                        programId: String(programId),
                                      })
                                    },
                                  },
                                )
                              }}
                            >
                              <Button
                                danger
                                icon={<Icons.UserDeleteOutlined />}
                                block
                                disabled={disableDeactivate}
                                size="small"
                              >
                                Nonaktifkan
                              </Button>
                            </PopDeleteConfirm>
                          ) : (
                            <PopDeleteConfirm
                              title="Apakah Anda yakin ingin mengaktifkan kembali peserta ini?"
                              validateValue={record.email}
                              placeholder="Tulis ulang email peserta"
                              disabled={disableReactivate}
                              okText="Aktifkan"
                              cancelText="Batal"
                              placement="topRight"
                              errorMessage="Email user tidak sesuai."
                              onConfirm={() => {
                                doDeactivate(
                                  {
                                    resource: `programs/${programId}/local-programs/${programLocalId}/classes/${id}/participants/reactivate`,
                                    values: {
                                      userId: record.userId,
                                    },
                                    successNotification: false,
                                    errorNotification: (err) =>
                                      showErrorNotification(
                                        err,
                                        'Terdapat gangguan ketika mengaktifkan kembali peserta',
                                      ),
                                  },
                                  {
                                    onSettled() {
                                      handleInvalidateParticipantList()
                                    },
                                    onSuccess() {
                                      successMigrateNotification({
                                        type: 'reactivate',
                                        programId: String(programId),
                                      })
                                    },
                                  },
                                )
                              }}
                            >
                              <Button
                                type="dashed"
                                icon={<Icons.UserAddOutlined />}
                                block
                                disabled={disableReactivate}
                                size="small"
                              >
                                Aktifkan Kembali
                              </Button>
                            </PopDeleteConfirm>
                          )}
                        </>
                      )}
                    </Space>
                  )
                }}
              />
            </Table>
          </div>
          {participantEmail && (
            <div className="ml-4 w-[500px]">
              <ParticipantDetails />
            </div>
          )}
        </div>
      </SideFilterParticipantForm>
      <ActorMigrationModal
        {...actorMigrationModalProps}
        initialLocalProgram={localProgram}
        initialClass={participantClass}
        onMigrationSettled={handleInvalidateParticipantList}
      />
      <ActorAdditionModal
        {...actorAdditionModalProps}
        onAdditionSettled={handleInvalidateParticipantList}
      />
    </WithParticipantFilterContext>
  )
}
