import {
  Descriptions,
  Popconfirm,
  Button,
  Icons,
  StepsProps,
  useModalForm,
  TextField,
  Badge,
} from '@pankod/refine-antd'
import dayjs from 'dayjs'
import React from 'react'
import { useCan, useOne } from '@pankod/refine-core'
import clsx from 'clsx'

import {
  PROGRAM_STATE,
  TLMSProgramDetail,
  TimespendDurationResponse,
} from '../types'
import { secondToMinuteText } from '../utils'
import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
import { ModalFormProgramDuration } from './blocks/ModalFormProgramDuration'
import { useProgramStore } from '../store'

const ENABLE_TIMESPEND_PROGRAM = false

type ProgramDetailProps = {
  data?: TLMSProgramDetail
  status: {
    status: [StepsProps['status'], StepsProps['status'], StepsProps['status']]
    nextState: PROGRAM_STATE | null
  }
  onUpdateStatus: () => void
  isUpdatingStatus: boolean
}
export const ProgramDetail = ({
  data,
  status,
  onUpdateStatus,
  isUpdatingStatus,
}: ProgramDetailProps) => {
  const { data: canAccessTimespend } = useCan({
    action: 'field',
    resource: 'program-timespend',
    params: { field: 'detail' },
  })

  const isOwner = useProgramStore(({ isOwner }) => isOwner)

  const { data: targetDuration } = useOne<TimespendDurationResponse>({
    resource: `programs/${data?.id}/timespends`,
    dataProviderName: 'lms',
    id: 'target-duration',
    queryOptions: {
      enabled:
        ENABLE_TIMESPEND_PROGRAM &&
        Boolean(canAccessTimespend?.can && !!data?.id),
    },
  })

  const { data: canUpdateStatus } = useCan({
    action: 'field',
    resource: 'lms-program-management',
    params: { field: 'status' },
  })

  const {
    modalProps: configureModalProps,
    formProps: configureFormProps,
    submit: configureSubmit,
    show: configureShow,
  } = useModalForm({
    action: 'edit',
    resource: `programs/${data?.id}/timespends`,
    dataProviderName: 'lms',
    redirect: false,
    successNotification: {
      message: 'Target durasi berhasil diubah',
      type: 'success',
      description: 'Sukses',
    },
    errorNotification: (error) =>
      showErrorNotification(
        error,
        'There was a problem when creating jurnal modul',
      ),
  })

  const getBadgeColor = (status?: TLMSProgramDetail['status']) => {
    switch (status) {
      case PROGRAM_STATE.PREPARATION:
        return 'warning'
      case PROGRAM_STATE.RUNNING:
        return 'processing'
      case PROGRAM_STATE.ENDED:
        return 'error'
      default:
        return undefined
    }
  }

  const { async: asyncTarget, sync: syncTarget } = targetDuration?.data
    ?.data || { async: 0, sync: 0 }

  return (
    <>
      <Descriptions
        column={2}
        className="mb-6"
        labelStyle={{ fontWeight: 600 }}
        size="small"
        bordered
      >
        <Descriptions.Item label="ID">{data?.id}</Descriptions.Item>
        <Descriptions.Item label="SIS Account ID">
          {data?.sisAccountId || (
            <TextField italic type="secondary" value="Tidak tersedia" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Nama">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="Course Blueprint Name">
          {data?.blueprint?.name || (
            <TextField italic type="secondary" value="Tidak tersedia" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Periode">
          {dayjs(data?.startDate).format('DD MMM YYYY')} &mdash;{' '}
          {dayjs(data?.endDate).format('DD MMM YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Course Blueprint SIS ID">
          {data?.blueprint?.sisCourseId || (
            <TextField italic type="secondary" value="Tidak tersedia" />
          )}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          label="Status Program"
          className="capitalize font-bold"
        >
          <div className="flex items-center">
            <Badge
              status={getBadgeColor(data?.status)}
              className={clsx(
                data?.status !== PROGRAM_STATE.ENDED &&
                  'animate-[1s_pulse_infinite]',
              )}
            />
            <div className="mr-5">{data?.status}</div>
            <Popconfirm
              placement="topRight"
              title={
                <span>
                  Apakah Anda ingin mengubah status program menjadi{' '}
                  <strong>{status.nextState?.toLocaleUpperCase()}</strong>?
                </span>
              }
              onConfirm={onUpdateStatus}
              okText="Ubah Status"
              cancelText="Batalkan"
              disabled={
                !status.nextState ||
                isUpdatingStatus ||
                (!canUpdateStatus?.can && !isOwner)
              }
            >
              <Button
                disabled={
                  !status.nextState || (!canUpdateStatus?.can && !isOwner)
                }
                loading={isUpdatingStatus}
                icon={<Icons.DoubleRightOutlined />}
                size="small"
                type="primary"
              >
                Ubah Status
              </Button>
            </Popconfirm>
          </div>
        </Descriptions.Item>
      </Descriptions>

      {ENABLE_TIMESPEND_PROGRAM && canAccessTimespend?.can && (
        <>
          <div className="mt-8 mb-2 flex flex-row justify-between">
            <h3 className="text-lg font-semibold">Target Durasi Timespend</h3>
            <Button
              type="ghost"
              className="ml-2"
              onClick={() => configureShow('target-duration')}
              icon={<Icons.ToolOutlined />}
            >
              Ubah Target Durasi
            </Button>
          </div>

          <Descriptions
            bordered
            column={2}
            className="mt-4 mb-6"
            labelStyle={{ fontWeight: 600 }}
            size="small"
          >
            <Descriptions.Item span={2} label="Timespend (Async)">
              {secondToMinuteText(asyncTarget)}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Recording (Sync)">
              {secondToMinuteText(syncTarget)}
            </Descriptions.Item>

            <Descriptions.Item span={2} label="Durasi Total">
              {secondToMinuteText(asyncTarget + syncTarget)}
            </Descriptions.Item>
          </Descriptions>

          <ModalFormProgramDuration
            modalProps={configureModalProps}
            formProps={configureFormProps}
            onSubmit={configureSubmit}
          />
        </>
      )}
    </>
  )
}
