import { Drawer } from '@pankod/refine-antd'
import React, { useCallback, useState } from 'react'

import InstructorTable from '@resources/lms-management/webinar/blocks/instructor/InstructorTable'

export const useInstructorDrawer = () => {
  const [showId, setShowId] = useState<{
    show: boolean
    programId: string | number
  }>({ show: false, programId: '' })
  const onClose = useCallback(() => {
    setShowId((state) => ({
      ...state,
      show: false,
    }))
  }, [])

  return {
    show: (id: number | string) => {
      setShowId({ show: true, programId: id })
    },
    visible: !!showId.show,
    programId: showId.programId,
    onClose,
  }
}

type InstructorDrawerProps = {
  visible: boolean
  onClose: () => void
  programId: string | number
  useNewCertificate?: boolean
}

const InstructorDrawer = ({
  onClose,
  programId,
  visible,
  useNewCertificate,
}: InstructorDrawerProps) => {
  return (
    <Drawer
      title="Daftar Instruktur Webinar"
      visible={visible}
      onClose={onClose}
      destroyOnClose
      width="70%"
      maskClosable={false}
    >
      <InstructorTable
        useNewCertificate={useNewCertificate}
        programId={programId}
      />
    </Drawer>
  )
}

export default InstructorDrawer
