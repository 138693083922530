import {
  Button,
  Form,
  Icons,
  Modal,
  notification,
  Select,
  TextField,
  useSelect,
} from '@pankod/refine-antd'
import { useCreate, useGetIdentity } from '@pankod/refine-core'
import React, { useState } from 'react'

import { showErrorNotification } from '@resources/angkatan-ppg-management/utils'
type ExportTimespendButtonProps = {
  programId?: string | number
  isOwner: boolean
  can?: boolean
  onDaftarUnduhanClick?: () => void
}
const ExportTimespendButton = ({
  programId,
  can,
  isOwner,
  onDaftarUnduhanClick,
}: ExportTimespendButtonProps) => {
  const { data, isSuccess } = useGetIdentity()
  const LMSRole = data?.user?.LMSRole || ''
  const isAdminEntitas = LMSRole === 'admin-entitas'
  const [showModal, setShowModal] = useState(false)
  const [form] = Form.useForm()
  const programLocalId = Form.useWatch('programLocalId', form)
  const programModuleGroupId = Form.useWatch('programModuleGroupId', form)
  const exportType = Form.useWatch('exportType', form)
  const {
    selectProps: selectProgramModuleGroupProps,
    queryResult: queryProgramModuleGroupResult,
  } = useSelect({
    dataProviderName: 'lms',
    resource: `programs/${programId}/paket-modul`,
    queryOptions: { enabled: showModal },
    optionLabel: 'name',
    optionValue: 'id',
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })
  const {
    selectProps: selectProgramLocalProps,
    queryResult: queryProgramLocalResult,
  } = useSelect({
    dataProviderName: 'lms',
    resource: `${
      isAdminEntitas ? 'entities/' : ''
    }programs/${programId}/local-programs`,
    queryOptions: { enabled: showModal && isSuccess },
    optionLabel: 'name',
    optionValue: 'id',
    defaultValueQueryOptions: {
      enabled: false,
    },
    filters: [
      {
        field: 'pageSize',
        operator: 'eq',
        value: -1,
      },
    ],
  })

  const { mutateAsync, isLoading } = useCreate()
  return (
    <>
      {can && (
        <span onClick={() => setShowModal(true)}>Ekspor Laporan Timespend</span>
      )}
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        okButtonProps={{ loading: isLoading }}
        afterClose={() => {
          form.resetFields()
        }}
        onOk={async () => {
          await form.validateFields()

          try {
            await mutateAsync({
              dataProviderName: 'lms',
              resource: `programs/${programId}/program-attachment`,
              values: {
                type: exportType,
                programLocalId:
                  programLocalId === -1 ? undefined : [programLocalId],
                programModuleGroupId: programModuleGroupId,
              },
              successNotification: false,
              errorNotification: (error) =>
                showErrorNotification(
                  error,
                  'Terdapat gangguan saat mengunduh laporan',
                ),
            })
            notification.success({
              key: 'download_laporan_timespend',
              duration: 6,
              description: (
                <div>
                  <p>
                    Ekspor/unduh laporan sedang diproses, harap pantau pada menu
                    "Daftar Unduhan Laporan"
                  </p>
                  <Button
                    onClick={() => {
                      notification.close('download_laporan_timespend')
                      onDaftarUnduhanClick?.()
                    }}
                    icon={<Icons.DownloadOutlined />}
                    type="primary"
                  >
                    Daftar Unduhan Laporan
                  </Button>
                </div>
              ),
              type: 'success',
              message: 'Sukses',
            })
            setShowModal(false)
          } catch (e) {
            showErrorNotification(e, 'Terdapat gangguan saat mengunduh laporan')
          }
        }}
        okText="Unduh"
        cancelText="Batal"
        title="Ekspor Laporan Timespend"
      >
        <Form
          form={form}
          layout="vertical"
          validateMessages={{ required: '${label} harus diisi.' }}
        >
          <Form.Item
            name="exportType"
            label="Cakupan Ekspor"
            required
            rules={[{ required: true }]}
          >
            <Select
              placeholder="Pilih cakupan"
              options={[
                { label: 'Kelas (Semua)', value: 'timespend_detail_all' },
                {
                  label: 'Kelompok Aktivitas',
                  value: 'timespend_detail_activity_group',
                },
                {
                  label: 'Aktivitas',
                  value: 'timespend_detail_activity',
                },
              ]}
            />
          </Form.Item>

          <Form.Item
            name="programLocalId"
            label="Program Lokal"
            required
            rules={[{ required: true }]}
          >
            <Select
              {...selectProgramLocalProps}
              options={[
                ...(!isOwner
                  ? []
                  : [
                      {
                        label: <TextField value="Semua Program Lokal" italic />,
                        value: -1,
                      },
                    ]),
                ...(selectProgramLocalProps?.options || []),
              ]}
              loading={queryProgramLocalResult.isFetching}
              onSearch={() => {}}
              placeholder="Pilih program lokal"
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase?.()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>

          <Form.Item name="programModuleGroupId" label="Paket Modul">
            <Select
              {...selectProgramModuleGroupProps}
              loading={queryProgramModuleGroupResult.isFetching}
              onSearch={() => {}}
              placeholder="Pilih paket modul"
              filterOption={(input, option) =>
                (option!.label as unknown as string)
                  .toLowerCase?.()
                  .includes(input.toLowerCase())
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default ExportTimespendButton
