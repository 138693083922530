import {
  Button,
  Form,
  Icons,
  Input,
  Space,
  useTableReturnType,
} from '@pankod/refine-antd'
import { InputRef } from 'antd'
import { LogicalFilter } from '@pankod/refine-core'
import React, { useEffect, useRef } from 'react'

type InputFilterProps = {
  onClose: () => void
  dataIndex: string
  setFilters: useTableReturnType['setFilters']
  label: string
  currentFilters: LogicalFilter[]
  visible: boolean
}
const InputFilter = ({
  dataIndex,
  setFilters,
  onClose,
  label,
  currentFilters,
  visible,
}: InputFilterProps) => {
  const [form] = Form.useForm()
  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
      const value = currentFilters.find(
        ({ field }) => field === dataIndex,
      )?.value
      if (!value) {
        form.resetFields()
        return
      }
      form.setFields([{ name: dataIndex, value }])
    }
  }, [currentFilters, dataIndex, form, visible])

  const clearFilters = () => {
    setFilters((prevFilters) => [
      ...(prevFilters as LogicalFilter[]).filter(
        ({ field }) => field !== dataIndex,
      ),
      { field: 'page', operator: 'eq', value: 1 },
    ])
    onClose()
  }
  return (
    <div className="p-2 grid grid-cols-1">
      <Form
        form={form}
        layout="vertical"
        onFinish={(values) => {
          const value = values[dataIndex]
          if (!value) {
            clearFilters()
            return
          }

          setFilters([
            { field: dataIndex, operator: 'eq', value },
            { field: 'page', operator: 'eq', value: 1 },
          ])
          onClose()
        }}
      >
        <Form.Item name={dataIndex} label={label}>
          <Input autoComplete="off" ref={inputRef} />
        </Form.Item>
      </Form>
      <Space className="justify-self-end">
        <Button
          onClick={form.submit}
          icon={<Icons.FilterOutlined />}
          type="primary"
          size="small"
        >
          Filter
        </Button>
        <Button
          onClick={() => {
            form.resetFields()
            clearFilters()
          }}
          size="small"
          danger
        >
          Clear
        </Button>
      </Space>
    </div>
  )
}

export default InputFilter
